import React, { useEffect, useRef } from 'react'

import {
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    RefresherEventDetail,
} from '@ionic/react'
import { closestIndexTo, subDays } from 'date-fns'
import { RouteComponentProps, useHistory } from 'react-router'

import { Button, Centered, Flex, Heading, Icon, Page, Text } from '@components'
import { SortDirection } from '@components'
import { useGetClientsQuery } from '@graphql'
import { useAppState, useAuth, usePreferences } from '@hooks'
import {
    checkValue,
    Geo,
    getDistance,
    isPhone,
    SETUP_GEO_ALWAYS_PERMS,
    SETUP_GEO_PERMS,
    useCoordinates,
} from '@utils'

import { AddClientButton } from '../AddClient'
import { ClientFilterBar } from './ClientFilterBar'
import { Client, ClientFilter, Section, SortMethod } from './Clients.types'
import { matchClient } from './Clients.utils'
import { ClientSections } from './ClientSections'
import { add } from 'ionicons/icons'

export const Clients = (props: RouteComponentProps) => (
    <Page title="Clients">
        <IonContent>
            <ClientListContent {...props} />
        </IonContent>
    </Page>
)

const ClientListContent = ({ match }: RouteComponentProps) => {
    const synced = useRef(false)
    const { coords: coordinates, refreshLocation } = useCoordinates()
    const history = useHistory()
    const { user } = useAuth()
    const { loading, data, error, refetch } = useGetClientsQuery({ skip: !user })
    const clients = (data?.getClientsForUser || []) as Client[]
    const userSettings = data?.getUser || { notificationInterval: undefined }
    const queryParams = new URLSearchParams(history.location.search)
    const doAnotherPopby = queryParams.get('continue') === 'true'
    const preferences = usePreferences()
    const {
        filters: { clients: filter },
        setFilter,
    } = useAppState()

    const nonArchiveClients = clients.filter((client) => !client.archived)

    useEffect(() => {
        const getAlwaysPerms = async () => {
            const [geoPerms, geoAlwaysPerms] = await Promise.all([
                checkValue(preferences, SETUP_GEO_PERMS),
                checkValue(preferences, SETUP_GEO_ALWAYS_PERMS),
            ])

            if (geoPerms && !geoAlwaysPerms) {
                Geo!
                    .requestAlwaysPermissions()
                    .then(() => {
                        preferences.set({ key: SETUP_GEO_ALWAYS_PERMS, value: 'true' })
                    })
                    .catch(() => console.log('Encountered error getting always permissions'))
            }
        }

        if (Geo) {
            getAlwaysPerms()
        }
    }, [])

    // Only do this once, on render. Effectively on first client pull
    useEffect(() => {
        if (Geo && !synced.current && clients.length) {
            clients
                .filter((client) => !client.archived)
                .forEach((client) => {
                    Geo!
                        .addClient({
                            clientUserId: client.id,
                            latitude: client.address.coordinates.lat,
                            longitude: client.address.coordinates.lng,
                        })
                        .then((res) => {
                            console.log('Synced client with Swift. Added = ', res)
                        })
                })
            synced.current = true
        }
    }, [clients])

    useEffect(() => {
        if (Geo && clients.length) {
            const curDate = new Date()
            const _clients: string[] = []
            const futureBirthdays: Date[] = []
            // Create two lists that are aligned with each other. Make sure that
            // the birthday list has only FUTURE birthdays
            for (const client of clients) {
                if (client.birthday) {
                    const birthday = new Date(client.birthday)
                    birthday.setFullYear(curDate.getFullYear())
                    if (birthday < curDate) {
                        birthday.setFullYear(curDate.getFullYear() + 1)
                    }

                    _clients.push(client.id)
                    futureBirthdays.push(birthday)
                }
            }

            if (futureBirthdays.length) {
                console.log('Registering birthday notification')
                // Find the closest birthday to today
                const closestBirthdayIndex = closestIndexTo(curDate, futureBirthdays)
                if (closestBirthdayIndex) {
                    const _client = _clients[closestBirthdayIndex]
                    const birthday = futureBirthdays[closestBirthdayIndex]

                    // We want to notify a day ahead of time, so subtract two days
                    // because we notify at 9AM
                    if (curDate > subDays(birthday, 2)) {
                        const notificationDate = subDays(birthday, 1)
                        Geo.registerBirthdayNotification({
                            clientUserId: _client,
                            month: notificationDate.getMonth(),
                            day: notificationDate.getDate(),
                        })
                    }
                }
            }
        }
    }, [clients])

    useEffect(() => {
        // Reload clients whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()

            if (doAnotherPopby) {
                setFilter('clients', (prev: ClientFilter) => ({
                    ...prev,
                    method: SortMethod.DISTANCE,
                    direction: SortDirection.ASC,
                    sections:
                        prev.sections.includes(Section.ALL) || prev.sections.includes(Section.DUE)
                            ? prev.sections
                            : [Section.ACTIVE, Section.DUE],
                }))
                history.replace(match.url)
            }
        }
    }, [history, match.url, doAnotherPopby, refetch, refreshLocation])

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    if (error) {
        return (
            <Centered>
                <Text.p>There was an error fetching clients</Text.p>
                <Button variant="primary" onClick={async () => await refetch()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    if (clients.length === 0) {
        return (
            <Centered>
                <Heading as="h4">You haven't added any clients yet</Heading>
                <AddClientButton refetch={refetch} />
            </Centered>
        )
    }

    const isDueForPopby = (lastPopbyTimestamp: string | null) => {
        const notificationInterval = userSettings?.notificationInterval

        if (!lastPopbyTimestamp || !notificationInterval) {
            return true
        }

        let nextWindowOpen = new Date(lastPopbyTimestamp)
        nextWindowOpen.setDate(nextWindowOpen.getDate() + notificationInterval)

        return nextWindowOpen <= new Date()
    }

    const all = []
    const due = []
    const notDue = []
    const active = []
    const inactive = []
    const archived = []

    for (const client of clients.filter((client) => matchClient(filter, client))) {
        const _client = { ...client }

        _client.dueForPopby = isDueForPopby(client.lastPopby)
        _client.distance = coordinates
            ? getDistance(
                  {
                      lat: client.address.coordinates.lat,
                      lng: client.address.coordinates.lng,
                  },
                  {
                      lat: coordinates.latitude,
                      lng: coordinates.longitude,
                  },
              )
            : -1

        if (_client.archived) {
            archived.push(_client)
        } else if (!_client.notifications) {
            inactive.push(_client)
        } else if (_client.dueForPopby) {
            due.push(_client)
            active.push(_client)
        } else {
            notDue.push(_client)
            active.push(_client)
        }
        all.push(_client)
    }

    return (
        <>
            <IonGrid fixed={!isPhone}>
                <IonRefresher
                    slot="fixed"
                    onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
                        await Promise.all([refetch(), refreshLocation()])
                        event.detail.complete()
                    }}
                >
                    <IonRefresherContent />
                </IonRefresher>
                {user?.subscribed ? null : (
                    <Flex
                        alignItems="center"
                        gap={3}
                        borderBottom={'base'}
                        flexWrap={'wrap'}
                        onClick={() => {
                            history.push('/subscribe')
                        }}
                        py={1}
                    >
                        <Text.p color={'#3880ff'} textAlign={'center'} fontSize={13}>
                            You're using the free version of Pop-By Pro, which allows up to five
                            clients. Click here to upgrade for unlimited clients
                        </Text.p>
                    </Flex>
                )}
                <ClientFilterBar clients={clients} />
                <ClientSections
                    numTotal={clients.length}
                    all={all}
                    active={active}
                    due={due}
                    notDue={notDue}
                    inactive={inactive}
                    archived={archived}
                />
            </IonGrid>
            {user?.subscribed || nonArchiveClients.length < 5 ? (
                <AddClientButton refetch={refetch} />
            ) : (
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <Flex>
                        <IonFabButton
                            color="link"
                            onClick={() => {
                                history.push('/subscribe')
                            }}
                        >
                            <Icon icon={add} />
                        </IonFabButton>
                    </Flex>
                </IonFab>
            )}
        </>
    )
}
