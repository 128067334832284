import React, { useEffect, useState } from 'react'

import "cordova-plugin-purchase"
import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Preferences } from '@capacitor/preferences'
import { Style } from '@capacitor/status-bar'
import { IonChip, IonPage, IonSpinner } from '@ionic/react'
import { setStatusBarStyle } from '@theme'
import { arrowForward, lockClosedOutline, powerOutline } from 'ionicons/icons'

import { Box, Button, Centered, Content, Flex, Heading, Icon, Text } from '@components'
import { useGetCheckoutSessionLazyQuery } from '@graphql'
import { useAuth } from '@hooks'
import { isApp, isAppInBrowser, Product, SETUP_AFFILIATE, useSetup } from '@utils'
import { Duration, PRODUCTS } from '@utils'

import { IAPModal } from './IAPModal'
import { SubscriptionHighlight } from './SubscriptionHighlight'
import { useHistory } from 'react-router'

const logout = async () => {
    await Preferences.remove({ key: 'authToken' })
    await FirebaseAuthentication.signOut()
    window.location.assign('/login')
}

enum Stage {
    PURCHASING = 'PURCHASING',
    VERIFYING = 'VERIFYING',
    VERIFIED = 'VERIFIED',
}

export const SetupSubscription = () => {
    const [stage, setStage] = useState<Stage | undefined>(undefined)
    const [getCheckoutSession, { loading: gettingCheckoutSession }] =
        useGetCheckoutSessionLazyQuery()
    const { user } = useAuth()
    const { proceed } = useSetup()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedInterval, setSelectedInterval] = useState<Duration>()
    const history = useHistory()

    const showIAPTerms = (interval: Duration) => {
        setSelectedInterval(interval)
        setShowModal(true)
    }
    const dismissIAPTerms = () => setShowModal(false)

    const disabled = !user || stage !== undefined || gettingCheckoutSession

    const openWeb = async (interval: Duration) => {
        const { store, Platform } = CdvPurchase

        if (!disabled) {
            const product = PRODUCTS[interval]

            // We want to use stripe in the browser, but IAP in the app
            if (isApp && !isAppInBrowser) {
                const appleProduct = store.get(product.iapProduct, Platform.APPLE_APPSTORE)
                if (!appleProduct) {
                    console.error('Unable to find product in store.')
                    return
                }

                setStage(Stage.PURCHASING)

                // Place the order. Receipt validation and subscription
                // setting will take place outside of this block. Just
                // keep calling that update
                appleProduct.getOffer()!.order({
                    applicationUsername: user.email!,
                }).then(error => {
                    if (error) {
                        if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
                          // Purchase flow has been cancelled by user
                            console.log('Cancelled subscription purchase.')
                            setStage(undefined)
                        }
                        else {
                          // Other type of error, check error.code and error.message
                          console.error('Error while purchasing: ', error)
                        }
                    } else {
                        // Purchase has been successfully completed
                        console.log('Order placed. Waiting for update.', appleProduct)
                        setStage(Stage.VERIFYING)
                        proceed()
                    }
                })

            } else {
                // We're using Stripe: go to stripe's site for checkout.
                // First we need to grab a checkout session.
                const resp = await getCheckoutSession({
                    variables: {
                        duration: `${interval}`,
                        affiliate: (await Preferences.get({ key: SETUP_AFFILIATE })).value,
                    },
                })

                if (resp.data) {
                    window.location.assign(resp.data.getCheckoutSession.url)
                }
            }
        }
    }

    useEffect(() => {
        setStatusBarStyle(showModal ? Style.Dark : Style.Light)
    }, [showModal])

    if (stage === Stage.VERIFYING) {
        return (
            <IonPage>
                <Content>
                    <Centered>
                        <Flex flexDirection="column">
                            <Flex flexDirection="row" justifyContent="center" mb={4}>
                                <IonSpinner />
                            </Flex>
                            <Text.p>Purchase Approved...</Text.p>
                        </Flex>
                    </Centered>
                </Content>
            </IonPage>
        )
    }

    if (stage === Stage.VERIFIED) {
        return (
            <IonPage>
                <Content>
                    <Centered>
                        <Flex flexDirection="column">
                            <Flex flexDirection="row" justifyContent="center" mb={4}>
                                <IonSpinner />
                            </Flex>
                            <Text.p>Syncing Purchase...</Text.p>
                        </Flex>
                    </Centered>
                </Content>
            </IonPage>
        )
    }

    return (
        <IonPage>
            <Content>
                <Centered textAlign="left">
                    <Box bg="white" borderRadius={5} m={3} p={4} border="base">
                        <Box mx="auto">
                            <Flex justifyContent="center" alignItems="center" gap={3} mb={3}>
                                <Box>
                                    <img
                                        src="/assets/icon/icon.png"
                                        alt="Pop-By Pro Logo"
                                        style={{
                                            display: 'block',
                                            maxWidth: '100px',
                                            height: 'auto',
                                            margin: '0 auto',
                                        }}
                                    />
                                </Box>
                                <Heading as="h1"m={0} textAlign="center" width="auto">
                                    Pop-By Pro Subscription
                                </Heading>
                            </Flex>
                        </Box>
                        <Text.p>
                            The free version of Pop-By Pro lets you track up to 5 clients. Subscribe for unlimited client tracking.
                        </Text.p>
                        <Text.p>
                            We offer an auto-renewable subscription that allows you to efficiently
                            manage, visit, and engage with clients in your sphere of influence.
                        </Text.p>
                        <SubscriptionHighlight
                            icon={lockClosedOutline}
                            label="Secure"
                            description={
                                <>
                                    {isApp ? 'Apple' : 'Stripe'} handles all payments and your
                                    payment information never reaches our servers.
                                </>
                            }
                        />
                        <SubscriptionHighlight
                            icon={powerOutline}
                            label="Cancel Anytime"
                            description="No contracts. No fuss. Start with a 3-day trial period
                                (you won't be charged if you cancel before your trial ends)."
                        />
                        <Flex flexWrap="nowrap" mb={4}>
                            <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                            <Box mx={2} color="medium" style={{ whiteSpace: 'nowrap' }}>
                                choose a plan
                            </Box>
                            <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                        </Flex>
                        <SubscriptionBox
                            product={PRODUCTS[Duration.YEAR]}
                            best
                            disabled={disabled}
                            showIAPTerms={() => showIAPTerms(Duration.YEAR)}
                            openWeb={() => openWeb(Duration.YEAR)}
                        />
                        <SubscriptionBox
                            product={PRODUCTS[Duration.MONTH]}
                            disabled={disabled}
                            showIAPTerms={() => showIAPTerms(Duration.MONTH)}
                            openWeb={() => openWeb(Duration.MONTH)}
                        />
                        <Flex flexDirection={"row"} justifyContent={"center"}>
                            <Button onClick={() => history.push('/app/clients')} variant="link">
                                Cancel
                            </Button>
                            <Button onClick={logout} variant="link">
                                Sign Out
                            </Button>
                        </Flex>
                    </Box>
                </Centered>
            </Content>
            <IAPModal
                isOpen={showModal}
                dismiss={dismissIAPTerms}
                interval={selectedInterval}
                proceed={openWeb}
            />
        </IonPage>
    )
}

const SubscriptionBox = ({
    best,
    product,
    disabled,
    showIAPTerms,
    openWeb,
}: {
    best?: boolean
    product: Product
    disabled: boolean
    showIAPTerms: () => void
    openWeb: () => void
}) => {
    return (
        <Box
            borderRadius={8}
            p={3}
            border={best ? 'blueHighlight' : 'base'}
            width={1}
            bg="light"
            mb={best ? 4 : 0}
        >
            <Flex flexWrap={['wrap', 'nowrap']} alignItems="center" gap={4}>
                <Box>
                    <Flex alignItems="center" mb={1} height={32}>
                        <Heading as="h5"mt={0} mb={0}>
                            {product.label}
                        </Heading>
                        {best && (
                            <Box
                                as={IonChip}
                                color="green"
                                bg="greens.3"
                                border="greenOutline"
                                px="10px"
                                py="6px"
                                m={0}
                                ml={2}
                                height="auto"
                                fontSize="small"
                            >
                                Best value
                            </Box>
                        )}
                    </Flex>
                    <Text.p color="medium" fontSize="small" mb={0}>
                        3-day trial then <strong>{product.price}</strong>
                    </Text.p>
                </Box>
                <Button
                    disabled={disabled}
                    onClick={isApp ? showIAPTerms : openWeb}
                    m={0}
                    ml="auto"
                    variant="primary"
                    borderRadius="12px"
                >
                    <Text.p mb={0} mr={1}>
                        {disabled ? 'Processing...' : 'Start Trial'}
                    </Text.p>
                    <Icon icon={arrowForward} size={20} />
                </Button>
            </Flex>
        </Box>
    )
}
