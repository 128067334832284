import React, { useState } from 'react'

import { Box, Button, Flex, Heading, Page, Text } from '@components'
import { useGetClientsQuery, useHandleUnsubscribeMutation } from '@graphql'
import { useAppState } from '@hooks'
import {
    IonCheckbox,
    IonItem,
    IonLabel,
    IonPage,
    IonSearchbar,
    IonSpinner,
    useIonAlert,
} from '@ionic/react'

import { Client } from './Clients/Clients/Clients.types'
import { Centered, Content } from '@components'
import { useHistory } from 'react-router'

export const HandleUnsubscribe = ({ onFinish }: { onFinish: () => void }) => {
    const { user } = useAppState()
    const [selectedClients, setSelectedClients] = useState<string[]>([])
    const [filter, setFilter] = useState<string | null | undefined>(null)
    const history = useHistory()
    const { loading, data, error, refetch } = useGetClientsQuery({ skip: !user })
    const [unsubscribeMutation, { error: unsubscribeError, loading: unsubscribeLoading }] =
        useHandleUnsubscribeMutation()
    const clients = (data?.getClientsForUser || []) as unknown as Client[]

    const nonArchivedClients = clients.filter((client) => {
        if (client.archived) {
            return false
        }

        if (filter) {
            const name = `${client.firstName} ${client.lastName}`
            return name.toLowerCase().includes(filter.toLowerCase())
        }

        return true
    })

    const handleArchive = async () => {
        await unsubscribeMutation({ variables: { clientUIDs: selectedClients } })
        await onFinish()

        history.push('/app/clients')

        onFinish()
    }

    if (loading || unsubscribeLoading) {
        return (
            <IonPage>
                <Content>
                    <Centered>
                        <IonSpinner />
                    </Centered>
                </Content>
            </IonPage>
        )
    }

    if (error) {
        return (
            <Centered>
                <Text.p>There was an error fetching clients</Text.p>
                <Button variant="primary" onClick={async () => await refetch()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    if (unsubscribeError) {
        return (
            <Centered>
                <Text.p>There was an archiving clients</Text.p>
                <Button variant="primary" onClick={async () => handleArchive()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    return (
        <Page title="Archive Clients">
            <Content>
                <Flex flexDirection={'column'} p={0} height="100%" mb={3}>
                    <Box borderBottom={'base'} mx={4} my={2}>
                        <Heading as="h3">Thank you for using Pop-By Pro</Heading>
                        <Text.p fontSize={14}>
                            By cancelling your subscription you can still use Pop-By Pro for up to 5
                            clients. Pick the 5 clients that you would like to keep in the app. The
                            remaining clients will be archived for later use if you choose to
                            resubscribe.
                        </Text.p>
                    </Box>

                    <Flex alignItems={'center'} flexDirection={'column'}>
                        <Button
                            disabled={selectedClients.length === 0}
                            variant="link"
                            onClick={handleArchive}
                        >
                            Archive Unselected Clients
                        </Button>
                    </Flex>

                    <Flex>
                        <IonSearchbar
                            placeholder={'Search Clients'}
                            onIonInput={(e) => {
                                setFilter(e.detail.value)
                            }}
                            value={filter}
                        />
                    </Flex>

                    <Box overflowY={'auto'} style={{ flex: 1 }}>
                        {nonArchivedClients.map((client) => (
                            <IonItem
                                key={client.id}
                                style={{
                                    paddingTop: '2px',
                                    paddingBottom: '2px',
                                    marginRight: '16px',
                                }}
                            >
                                <IonCheckbox
                                    disabled={selectedClients.length >= 5}
                                    checked={selectedClients.includes(client.id)}
                                    onIonChange={(evt) => {
                                        let checked = evt.detail.checked
                                        if (checked) {
                                            setSelectedClients((prev) => {
                                                if (!prev.includes(client.id) && prev.length < 5) {
                                                    return [...prev, client.id]
                                                }
                                                return prev
                                            })
                                        } else {
                                            setSelectedClients((prev) =>
                                                prev.filter((id) => id !== client.id),
                                            )
                                        }
                                    }}
                                />
                                <IonLabel>
                                    {client.firstName} {client.lastName}
                                </IonLabel>
                            </IonItem>
                        ))}
                    </Box>
                </Flex>
            </Content>
        </Page>
    )
}
